<template>
  <div class="center">
    <el-menu
      :default-active="activeIndex"
      class="center-menu"
      :style="{ top: menuTop + 'px' }"
    >
      <el-menu-item index="1" @click="navigate('/center')">
        <i class="el-icon-ali10"></i>
        <span class="menu-title">{{ $t("my_homepage") }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <el-menu-item index="2" @click="navigate('/center/tasks-record')">
        <i class="el-icon-ali9"></i>
        <span class="menu-title">{{ $t("investigation_records") }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <el-menu-item index="3" @click="navigate('/center/exchange-record')">
        <i class="el-icon-ali1"></i>
        <span class="menu-title">{{ $t("exchange_history") }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <!-- <el-menu-item index="4" @click="navigate('/center/medal')">
        <img class="madel" :src="madel" />
        <span class="menu-title">{{ $t("my_medal") }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item> -->
      <el-menu-item index="5" @click="navigate('/center/messages')">
        <i class="el-icon-ali8"></i>
        <span class="menu-title">{{ $t("my_messages") }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <el-menu-item index="6" @click="navigate('/center/gold-record')">
        <i class="el-icon-ali7"></i>
        <span class="menu-title">{{ $t("gold_history") }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <el-menu-item index="7" @click="navigate('/center/setting')">
        <i class="el-icon-alisetting"></i>
        <span class="menu-title">{{ $t("account_setting") }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <el-menu-item index="8" @click="navigate('/center/recommend')">
        <i class="el-icon-ali4"></i>
        <span class="menu-title">{{ $t("my_invitation") }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <el-menu-item index="9" @click="navigate('/center/credit')">
        <i class="el-icon-ali12"></i>
        <span class="menu-title">{{ $t("my_credit") }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <el-menu-item index="10" @click="navigate('/center/card-coupons')">
        <img
          class="icon-xbzq"
          v-if="activeIndex != 10"
          src="@/assets/images/icon-kq.png"
        />
        <img class="icon-xbzq" v-else src="@/assets/images/icon-kq2.png" />
        <span class="menu-title">卡券专区</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <el-menu-item index="11" @click="navigate('/treasure-hunt')">
        <img class="icon-xbzq" src="@/assets/images/icon-xbzq.png" />
        <span class="menu-title">寻宝专区</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
      <el-menu-item index="12" @click="navigate('/prize-draw')">
        <img class="icon-xbzq" src="@/assets/images/icon-cj.png" />
        <span class="menu-title">抽奖专区</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
    </el-menu>
    <div class="center-right-container"><router-view></router-view></div>
  </div>
</template>
<style src="./index.scss" lang="scss" scoped></style>
<style>
.madel {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
</style>
<style scoped>
.icon-xbzq {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  margin-right: 5px;
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeIndex: "1",
      menuTop: 90,
      madel: require("@/assets/images/madel.png")
    };
  },
  created() {
    this.changeActive();
    if (this.account && this.account.isBlacklist) {
      this.$alert(
        this.$t("blacklist_tip1") + this.account.blacklistReason,
        this.$t("tip"),
        {
          confirmButtonText: this.$t("close"),
          type: "info"
        }
      );
    }
    this.listenScroll();
    window.addEventListener("scroll", this.listenScroll);
    window.addEventListener("resize", this.listenScroll);
  },
  watch: {
    $route: "changeActive"
  },
  updated() {
    this.$nextTick(this.listenScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.listenScroll);
    window.removeEventListener("resize", this.listenScroll);
  },
  methods: {
    listenScroll() {
      // console.log('this.menuTop',window.innerHeight,document.body.offsetHeight - document.scrollingElement.scrollTop)
      if (
        document.body.offsetHeight - document.scrollingElement.scrollTop <=
          799 &&
        window.innerHeight <= 799
      ) {
        this.menuTop =
          document.body.offsetHeight -
          document.scrollingElement.scrollTop -
          830; //，添加el-menu-item,增加730的数值,减少707相同数值
        console.log("this.menuTop", this.menuTop);
      } else {
        this.menuTop = 90;
      }
    },
    changeActive() {
      this.activeIndex = this.$route.meta.subactived;
      if (this.activeIndex == 4) {
        this.madel = require("@/assets/images/madel-active.png");
      } else {
        this.madel = require("@/assets/images/madel.png");
      }
      this.listenScroll();
      try {
        window.document.scrollingElement.scrollTo(0, 0);
      } catch (e) {
        console.info("sudiaoba warning:", e);
      }
    },
    async checkin() {
      const {
        data: { isComplete }
      } = await this.$http.post(`/api/v1/Accounts/${this.account.id}/Today`);
      if (isComplete) {
        this.$notify({
          title: this.$t("sign_success"),
          type: "success"
        });
      }
    },
    navigate(to) {
      if (
        to === this.$route.name ||
        (["/", "homepage"].includes(to) &&
          ["/", "homepage"].includes(this.$route.name))
      )
        return;
      this.$router.push(
        to,
        () => {},
        () => {}
      );
    }
  },
  computed: {
    ...mapGetters(["account"]),
    accountLevel() {
      return [this.$t("regular_member")][[1].indexOf(this.account.level) || 0];
    }
  }
};
</script>
